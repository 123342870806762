// Bootstrap Link
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
import "bootstrap-icons/font/bootstrap-icons.css";

// import './src/styles/style.css';
import './src/styles/style.scss'; // Correct spelling
import './src/styles/responsive.scss';
// import './src/styles/_variables.scss';
// import './src/js/app.js';

export const onRouteUpdate = ({ location, prevLocation }) => {
    window.scrollTo(0, 0);
};