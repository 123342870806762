exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-elevate-your-it-resume-strategies-to-stand-out-in-tech-js": () => import("./../../../src/pages/blog/elevate-your-it-resume-strategies-to-stand-out-in-tech.js" /* webpackChunkName: "component---src-pages-blog-elevate-your-it-resume-strategies-to-stand-out-in-tech-js" */),
  "component---src-pages-blog-emerging-trends-career-opportunities-js": () => import("./../../../src/pages/blog/emerging-trends-career-opportunities.js" /* webpackChunkName: "component---src-pages-blog-emerging-trends-career-opportunities-js" */),
  "component---src-pages-blog-what-do-employers-look-for-when-selecting-a-candidate-for-an-it-role-js": () => import("./../../../src/pages/blog/what-do-employers-look-for-when-selecting-a-candidate-for-an-it-role.js" /* webpackChunkName: "component---src-pages-blog-what-do-employers-look-for-when-selecting-a-candidate-for-an-it-role-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-seeker-js": () => import("./../../../src/pages/job-seeker.js" /* webpackChunkName: "component---src-pages-job-seeker-js" */),
  "component---src-pages-submit-a-position-js": () => import("./../../../src/pages/submit-a-position.js" /* webpackChunkName: "component---src-pages-submit-a-position-js" */),
  "component---src-pages-wbenc-js": () => import("./../../../src/pages/wbenc.js" /* webpackChunkName: "component---src-pages-wbenc-js" */),
  "component---src-pages-whyesspire-js": () => import("./../../../src/pages/whyesspire.js" /* webpackChunkName: "component---src-pages-whyesspire-js" */)
}

